<script lang="ts" setup>
const { assetBaseUrl } = useRuntimeConfig().public;
</script>

<template>
  <header>
    <nav class="bg-white py-2 shadow-sm">
      <div class="container flex items-center justify-between px-bs-container">
        <img
          :src="assetBaseUrl + '/frontend/images/yazio-logo.svg'"
          class="h-[4.5rem] w-40 py-3"
          type="image/svg+xml"
          alt="YAZIO Logo"
        />
        <slot />
      </div>
    </nav>
  </header>
</template>
