<template>
  <footer class="mt-auto border-t border-neutral-200 p-4">
    <div class="container px-bs-container">
      <p class="mb-0 text-center text-xs">
        <span>Copyright &copy; {{ new Date().getFullYear() }} YAZIO. All rights reserved.&nbsp;</span>
        <a href="https://www.yazio.com/contact" target="_blank">
          {{ $t("app.general.label.contact") }}
        </a>
      </p>
    </div>
  </footer>
</template>
